










import { Component, Prop, Vue } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton } from 'bootstrap-vue'
import RessifApexDonutChart from "../templates/donut-charts/RessifApexDonutChart.vue"
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";

@Component({
  components: {
    SearchableVueSelect,
    BButton,
    RessifApexDonutChart,
  },
  name: "donut-consultations-ist-by-sexe-chart"
})
export default class DonutConsultationsIstBySexeChart extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique;
  @Prop({ required: true }) model!: any;
  @Prop({ required: true }) reference!: any;

  title = "Accompagnement Individuel";
  description =
    "(032-1) Répartition du nombre de consultations IST par sexe";

  tableFields = [
    { key: "sexe", label: "Sexe", type: "text", editable: true },
    { key: "nombrePI", label: "Nombre de consultations", type: "number", editable: true },
    { key: "actions", label: "Actions", editable: false },
  ];
  sampleDatas = [
    {
      id: 1,
      sexe: "Homme",
      nombrePI: 25,
    },
    {
      id: 2,
      sexe: "Femme",
      nombrePI: 35,
    },
    {
      id: 3,
      sexe: "Non Connu / Autres",
      nombrePI: 40,
    }
  ]

  addLinePayload = {
    sexe: "Autres",
    nombrePI: 0,
  }
  seriesArr: any[] | null = null;
  categoriesArr: any[] | null = null;

  typeGraph = TypeGraphEnum.Individuel;
  modelCopy: any | null = null;

  created() {
    this.modelCopy = JSON.parse(JSON.stringify(this.model));
  }

  onRowDeleted(payload: any) {
    this.sampleDatas = this.sampleDatas.filter(x => x.id !== payload.id)
    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      var payloadMapped = this.sampleDatas.map((x: any) => x.nombrePI);
      let dataArray = payloadMapped;
      let seriesArray = this.sampleDatas.map((x: any) => x.sexe)

      this.seriesArr = dataArray
      this.categoriesArr = seriesArray as any[]
      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef].updateOptions(
        {
          labels: this.categoriesArr
        }, false, true, true
      )
    }, 200);
  }
  onRowAdded(payload: any) {
    this.sampleDatas = payload.values

    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      var payloadMapped = this.sampleDatas.map((x: any) => parseInt(x.nombrePI));
      let dataArray = payloadMapped;
      let seriesArray = this.sampleDatas.map((x: any) => x.sexe)

      this.seriesArr = dataArray
      this.categoriesArr = seriesArray as any[]
      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef]?.updateOptions(
        {
          labels: this.categoriesArr
        }, false, true, true
      )
    }, 200);
  }
  async loadConsultationsISTBySexe(graphRef: any) {
    await this.$http.ressifnet.rapportActivites
      .repartitionConsultationsISTBySexe(this.modelCopy)
      .then((response: any) => {
        var responseMapped = response.map((x: any) => x.nombrePI)
        let dataArray = responseMapped
        let seriesArray = response.map((x: any) => x.sexe)

        this.seriesArr = dataArray
        this.categoriesArr = seriesArray as any[]
        this.sampleDatas = response.map((x: any, index: number) => {
          return {
            id: x.id,
            sexe: x.sexe,
            nombrePI: x.nombrePI,
          }
        });

        (this.$refs[graphRef] as any).$refs[graphRef]?.updateOptions(
          {
            labels: this.categoriesArr
          }, false, true, true
        );
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = false;
      }).catch((e: any) => {
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = true;
        (this.$refs[graphRef] as any).disabledButtonTrueData = false;

        let message = e.response?.data?.errors ?
          `Un problème est survenu lors du chargement du graphique: ${Object.values(e.response.data.errors)[0]}` :
          "Un problème est survenu lors du chargement du graphique";

        this.$swal({
          title: 'Erreur',
          text: message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      })
  }

  async loadTrueData(graphRef: any) {
    await this.loadConsultationsISTBySexe(graphRef);
  }

  toggleExportToList(graphRef: any) {
    this.$emit('toggle-export-to-list', graphRef)
  }
  baseConfig = {
    series:  this.sampleDatas.map((x: any) => x.nombrePI),
    chartOptions: {
      chart: {
        type: 'donut',
      },
      labels: this.sampleDatas.map((x: any) => x.sexe),
      dataLabels: {
        style: {
          fontSize: '17px'
        }
      },
      legend: {
        position: 'bottom',
        fontSize: '17px',
        offsetX: 50,
        width: 550,
        formatter: function (val: any, opts: any) {
          //  let total = 0;            
          //  for (let x of opts.w.config.series) { total += x; } 
          let selected = opts.w.config.series[opts.seriesIndex]
          return opts.w.config.labels[opts.seriesIndex] + " (" + selected + ")";
        }
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val + ""
          }
        }
      },
    }

  }
}
