






























import { Component, Prop, Vue } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton } from "bootstrap-vue";
import RessifApexBarChart from "../templates/bar-charts/RessifApexBarChart.vue";
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";

@Component({
  components: {
    SearchableVueSelect,
    BButton,
    RessifApexBarChart,
  },
  name: "bar-orientation-consultations-mdt-chart",
})
export default class BarOrientationConsultationsMdtChart extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique;
  @Prop({ required: true }) model!: any;
  @Prop({ required: true }) reference!: any;

  title = "Accompagnement individuel - Visites sous délégation MDT";
  description = "(043) Répartition par orientation des consultations sous délégation MDT";

  tableFields = [
    { key: "orientation", label: "Orientation", type: "text", editable: true },
    { key: "nombrePI", label: "Nombre", type: "number", editable: true },
    { key: "pourcentage", label: "Pourcentage", type: "number", editable: true },
    { key: "actions", label: "Actions", editable: false },
  ];
  sampleDatas = [
  {
    id: 1,
    orientation: "Orientation 1",
    nombrePI: 10,
    pourcentage: 10
  },
  {
    id: 2,
    orientation: "Orientation 2",
    nombrePI: 20,
    pourcentage: 20
  },
]

  addLinePayload = {
    orientation: "Autres",
    nombrePI: 0,
    pourcentage: 0
  };

  seriesArr: any[] | null = null;
  categoriesArr: any[] | null = null;

  typeGraph = TypeGraphEnum.Individuel;
  modelCopy: any | null = null;

  created() {
    this.modelCopy = JSON.parse(JSON.stringify(this.model));
  }

  onRowDeleted(payload: any) {
    this.sampleDatas = this.sampleDatas.filter(x => x.id !== payload.id)
    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      var payloadMapped = this.sampleDatas.map((x: any) => `${x.pourcentage}% (${x.nombrePI})`)
      let dataArray = payloadMapped
      let seriesArray = this.sampleDatas.map((x: any) => x.orientation)
      this.seriesArr = [
        {
          key: "Pourcentage",
          name: "Pourcentage",
          data: dataArray
        },
      ];
      this.categoriesArr = seriesArray as any[]
      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef].updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }
  onRowAdded(payload: any) {
    this.sampleDatas = payload.values
    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      var payloadMapped = this.sampleDatas.map((x: any) => `${x.pourcentage}% (${x.nombrePI})`)
      let dataArray = payloadMapped
      let seriesArray = this.sampleDatas.map((x: any) => x.orientation)
      this.seriesArr = [
        {
          key: "Pourcentage",
          name: "Pourcentage",
          data: dataArray
        },
      ];
      this.categoriesArr = seriesArray as any[];
      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef]?.updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }
  async loadData(graphRef: any) {
    await this.$http.ressifnet.rapportActivites
      .repartitionOrientationConsultationMDT(this.model)
      .then((response: any) => {
        var responseMapped = response.map((x: any) => `${x.pourcentage.toFixed(1)}% (${x.nombrePI})`)
        let dataArray = responseMapped
        let seriesArray = response.map((x: any) => x.orientation)
        this.seriesArr = [
          {
            key: "Pourcentage",
            name: "Pourcentage",
            data: dataArray
          },
        ];
        this.sampleDatas = response.map((x: any, index: number) => {
          return {
            id: x.id,
            orientation: x.orientation,
            nombrePI: x.nombrePI,
            pourcentage: x.pourcentage.toFixed(1)
          }
        });
        this.categoriesArr = seriesArray as any[]
        (this.$refs[graphRef] as any).$refs[graphRef]?.updateOptions(
          {
            xaxis: {
              categories: this.categoriesArr
            }
          }, false, true, true
        );
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = false;
      }).catch((e: any) => {
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = true;
        (this.$refs[graphRef] as any).disabledButtonTrueData= false;
        let message = e.response?.data?.errors ? 
          `Un problème est survenu lors du chargement du graphique: ${Object.values(e.response.data.errors)[0]}` :
          "Un problème est survenu lors du chargement du graphique";
        this.$swal({
          title: 'Erreur',
          text: message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      })
  }

  async loadTrueData(graphRef: any) {
    await this.loadData(graphRef);
  }

  toggleExportToList(graphRef: any) {
    this.$emit("toggle-export-to-list", graphRef);
  }
  
  baseConfig = {
    series: [
      {
        key: "Nombre",
        name: "Nombre",
        data: [10, 20],
      }
    ],
    chartOptions: {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any, opts: any) {
          var index = opts.dataPointIndex;
          return opts.config.series[0].data[index];
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '18px',
        width: 550,
        offsetX: 50,
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: [
          'Orientation 1', 
          'Orientation 2',
        ],
        labels: {
          style: {
            fontSize: '18px'
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          trim: false,
          style : {
            fontSize: '18px'
          }
        }
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val + "%";
          }
        }
      },
    }
  }
}
