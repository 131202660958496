export enum EnumDouleurPhysiqueStat {
  Cervicale = "CERVICALE",
  Cou = "COU",
  Cheville = "CHEVILLE",
  Doigt = "DOIGT",
  Dos = "DOS",
  Jambe = "JAMBE",
  Lombalgies = "LOMBALGIES",
  Main = "MAIN",
  Oeil = "OEIL",
  Poignets = "POIGNETS",
  Tete = "TETE",
  Thorax = "THORAX",
  Abdomen = "ABDOMEN",
  Autre = "AUTRE",
  Pied = "PIED",
  Coude = "COUDE",
  Epaule = "EPAULE",
  Genou = "GENOU",
  Visage = "VISAGE"
}