






























import { Component, Prop, Vue } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton } from "bootstrap-vue";
import RessifApexBarChart from "../templates/bar-charts/RessifApexBarChart.vue";
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";

@Component({
  components: {
    SearchableVueSelect,
    BButton,
    RessifApexBarChart,
  },
  name: "bar-dossiers-by-maladie-pro-chart",
})
export default class BarDossiersByMaladieProChart extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique;
  @Prop({ required: true }) model!: any;
  @Prop({ required: true }) reference!: any;

  title = "Partie individuelle - Dossiers salariés";
  description =
    "(007) Répartition des dossiers par codes de MP";

  tableFields = [
    { key: "maladiePro", label: "MaladiePro", type: "text", editable: true },
    { key: "nombre", label: "Nombre", type: "number", editable: true },
    { key: "actions", label: "Actions", editable: false },
  ];

  sampleDatas = [
  {
    id: 1,
    maladiePro: "Maladie pro 1",
    nombre: 10,
  },
  {
    id: 2,
    maladiePro: "Maladie pro 2",
    nombre: 20,
  },
  {
    id: 3,
    maladiePro: "Maladie pro 3",
    nombre: 12,
  },
  {
    id: 4,
    maladiePro: "Maladie pro 4",
    nombre: 11,
  },
]

  addLinePayload = {
    maladiePro: "Autres",
    nombre: 0,
  };

  seriesArr: any[] | null = null;
  categoriesArr: any[] | null = null;

  typeGraph = TypeGraphEnum.Individuel;
  modelCopy: any | null = null;

  created() {
    this.modelCopy = JSON.parse(JSON.stringify(this.model));
  }

  onRowDeleted(payload: any) {
    this.sampleDatas = this.sampleDatas.filter(x => x.id !== payload.id)
    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      let dataArray = this.sampleDatas.map((x: any) => parseInt(x.nombre))
      let seriesArray = this.sampleDatas.map((x: any) => x.maladiePro)

      this.seriesArr = [
        {
          key: "Nombre",
          name: "Nombre",
          data: dataArray
        },
      ];
      this.categoriesArr = seriesArray as any[]
      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef].updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }

  onRowAdded(payload: any) {

    this.sampleDatas = payload.values

    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {

      let dataArray = this.sampleDatas.map((x: any) => parseInt(x.nombre))
      let seriesArray = this.sampleDatas.map((x: any) => x.maladiePro)

      this.seriesArr = [
        {
          key: "Nombre",
          name: "Nombre",
          data: dataArray
        },        
      ];
      this.categoriesArr = seriesArray as any[];

      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef]?.updateOptions(
        {
          xaxis: {
            categories: this.categoriesArr
          }
        }, false, true, true
      )
    }, 200);
  }

  async loadDossiersByMaladiePro(graphRef: any) {
    await this.$http.ressifnet.rapportActivites
      .repartitionDossiersByMaladiePro(this.model)
      .then((response: any) => {
        let dataArray = response.map((x: any) => parseInt(x.nombre))
        let seriesArray = response.map((x: any) => x.maladiePro)

        this.seriesArr = [
          {
            key: "Nombre",
            name: "Nombre",
            data: dataArray
          }
        ];

        this.categoriesArr = seriesArray as any[]
        this.sampleDatas = response as any[];

        (this.$refs[graphRef] as any).$refs[graphRef]?.updateOptions(
          {
            xaxis: {
              categories: this.categoriesArr
            }
          }, false, true, true
        );
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = false;
      }).catch((e: any) => {
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = true;
        (this.$refs[graphRef] as any).disabledButtonTrueData= false;

        let message = e.response?.data?.errors ? 
          `Un problème est survenu lors du chargement du graphique: ${Object.values(e.response.data.errors)[0]}` :
          "Un problème est survenu lors du chargement du graphique";

        this.$swal({
          title: 'Erreur',
          text: message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      })
  }

  async loadTrueData(graphRef: any) {
    await this.loadDossiersByMaladiePro(graphRef);
  }

  toggleExportToList(graphRef: any) {
    this.$emit("toggle-export-to-list", graphRef);
  }
  
  baseConfig = {
    series: [
      {
        key: "Nombre",
        name: "Nombre",
        data: [10, 20],
      }
    ],
    chartOptions: {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: false,
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '18px',
        width: 550,
        offsetX: 50,
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: [
          'Maladie pro 1', 
          'Maladie pro 2',
        ],
        labels: {
          style: {
            fontSize: '18px'
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          trim: false,
          style : {
            fontSize: '18px'
          }
        }
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val
          }
        }
      },
    }
  }
}
