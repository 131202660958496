
















































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { TypeColonneEnum } from "@/api/models/enums/typeColonne";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";
import {
  ExtraFilterShort,
  ExtraFilterModel,
} from "@/api/models/rapportActivites/rapportActivite";
import { BModal, BButton, BFormSelect, BFormSelectOption } from "bootstrap-vue";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { required } from "@validations";

@Component({
  components: {
    BModal,
    BButton,
    BFormSelect,
    BFormSelectOption,
    ValidationObserver,
    ValidationProvider,
    SearchableVueSelect,
    DeleteIcon,
    RessifnetDateInput,
  },
  name: "ressif-graph-filters",
})
export default class RessifGraphFilters extends Vue {
  @Prop({ required: true }) graphRef!: string;
  @Prop({ required: false }) typeGraph!: TypeGraphEnum;

  @Prop({ required: false }) filters!: ExtraFilterModel[];

  typeColonneEnum = TypeColonneEnum;

  filtersList: ExtraFilterShort[] = [];
  totalCountFilters: number = 0;

  required = required;

  created() {
    if (this.filters && this.typeGraph) this.searchFilters(null);
  }

  openModal() {
    this.$bvModal.show("filtersModal" + this.graphRef);
  }

  // Filters Methods
  async searchFilters(params: any) {
    if (!params || params?.reset) {
      this.filtersList = this.filtersList?.filter((e) =>
        this.filtersList
          .map((f) => {
            return f.id;
          })
          .includes(e.id),
      );
      this.totalCountFilters = this.filtersList.length;
    }

    await this.$http.ressifnet.rapportActivites
      .extraFiltersByTypeGraph(
        this.typeGraph,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search,
      )
      .then((res: PaginatedList<ExtraFilterShort>) => {
        this.totalCountFilters = res.totalCount;
        this.filtersList = this.filtersList!.concat(
          res.items.filter(
            (ri) => !this.filtersList!.some((lsi: any) => lsi.id == ri.id),
          ),
        );
      })
      .catch(function (e: any) {
        console.error(e);
      });
  }

  addFilter() {
    if (this.filters) {
      this.filters.push({
        id: undefined,
        valueId: undefined,
        comparator: undefined,
        op: undefined,
        value: undefined,
      });
    }
  }

  removeFilter(filterId: string) {
    if (this.filters) {
      let index = this.filters.findIndex((f) => f.id == filterId);
      this.filters.splice(index, 1);
    }
  }

  clearFilter(filter: any) {
    filter.filterId = undefined;
    filter.valueId = undefined;
    filter.comparator = undefined;
    filter.op = undefined;
  }

  getAvailableComparators(filterId: string) {
    let filter: ExtraFilterShort | undefined = this.filtersList.find(
      (f) => f.id === filterId,
    );
    if (!filter) return [];
    else {
      if (filter.typeColonne === TypeColonneEnum.Boolean) {
        // Si le type est un booléen, retourne uniquement les comparateurs nécessaires
        return [
          { value: "=", text: "Égal à" },
          { value: "!=", text: "Différent de" },
        ];
      } else {
        // Pour les autres types de champs, retourne tous les comparateurs disponibles
        return [
          { value: "=", text: "Égal à" },
          { value: "!=", text: "Différent de" },
          { value: "<", text: "Inférieur à" },
          { value: "<=", text: "Inférieur ou égal à" },
          { value: ">", text: "Supérieur à" },
          { value: ">=", text: "Supérieur ou égal à" },
        ];
      }
    }
  }

  hasValue(filterId: string) {
    let filter: ExtraFilterShort | undefined = this.filtersList.find(
      (f) => f.id === filterId,
    );
    return filter?.hasValue;
  }

  getTypeColonne(filterId: string) {
    let filter: ExtraFilterShort | undefined = this.filtersList.find(
      (f) => f.id === filterId,
    );
    return filter?.typeColonne;
  }

  hasNextFilter(filter: ExtraFilterModel): boolean {
    return this.filters.indexOf(filter) + 1 !== this.filters.length;
  }

  getFilterValues(filterId: string) {
    let filter: ExtraFilterShort | undefined = this.filtersList.find(
      (f) => f.id === filterId,
    );
    if (!filter) return [];
    return filter.valeurs;
  }

  saveExtraFilters() {
    // ...
    this.$emit("ok");
  }
}
