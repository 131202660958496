export enum EnumNumberStat {

  NombreDossiersActifs = "DOSSIERSACTIFS",
  NombreSalariesImpactesTypeAtionSstAmt = "NBSALARIESIMPACTESTYPEACTIONFORMATIONSSTAMT",
  
  NombreEDP = "NBEDP",
  
  NombreDossiersActifsMDT = "DOSSIERSACTIFSMDT",
  NombreDossiersActifsRQTH = "DOSSIERSACTIFSRQTH",
  NombreDossiersActifsInvalidite = "DOSSIERSACTIFSINVALIDITE",
  NombreDossiersActifsMP = "DOSSIERSACTIFSMP",
  nombreDossiersActifsCONSIST = "DOSSIERSACTIFSCONSIST",

  
  NombreDossiersActifsEDP = "DOSSIERSACTIFSEDP",
  NombreEdp = "NBEDP",
  NombreTpsTotalEDP = "TPSTOTALEDP",


  NombrePI = "NBPI",
  NombrePIConsultationIST = "NBPI_CONSULTATION_IST",
  NombrePIConsultationISTDouleursPsychiques = "NBPI_CONSULTATION_IST_DOULEURS_PSYCHIQUES",
  PourcentagePersonnesConsultationISTDouleursPsychiques = "POURCENTAGE_PERSONNES_CONSULTATION_IST_DOULEURS_PSYCHIQUES",
  PourcentagePersonnesVisiteDelegationMDTDouleursPhysiques = "POURCENTAGE_PERSONNES_VISITE_DELEGATION_MDT_DOULEURS_PHYSIQUES",
  NombreAMTEDP = "NBAMTEDP",
  NombreAmenagementPoste = "NBAMENAGEMENTPOSTE",
  NombreSalariesImpactesPointInformelADM = "NBSALARIESPOINTINFORMELADM",
  NombreTpsTotalActionADMSuiviPreco = "TPSTOTALACTIONADMSUIVIPRECO",
  NombreProjetsPst = "NBPROJETSPST",
  NombreTpsTotalActionPST = "TPSTOTALACTIONPST",
  NombreActionsPST = "NBACTIONSPST",
  NombreTpsTotalProjetsPST = "TPSTOTALPROJETSPST",
  NombreProjetsPsp = "NBPROJETSPSP",
  NombreTpsTotalActionPSP = "TPSTOTALACTIONPSP",
  NombreActionsPSP = "NBACTIONSPSP",
  NombreTpsTotalProjetsPSP = "TPSTOTALPROJETSPSP",
  NombrePITypeVisiteMDT = "NBPITYPEVISITEMDT",
  NombrePIConsultationMdtDouleursPsychiques = "NBPI_CONSULTATION_MDT_DOULEURS_PSYCHIQUES",
  PourcentagePersonnesVisiteDelegationMdtDouleursPsychiques = "POURCENTAGE_PERSONNES_VISITE_DELEGATION_MDT_DOULEURS_PSYCHIQUES",
  NombreVisiteDelegationMdtDouleurPhysiqueKey = "NOMBRE_VISITE_DELEGATION_MDT_DOULEUR_PHYSIQUE_KEY",
  RepartitionVisiteDelegationMdtDouleurPhysiqueKey = "REPARTITION_VISITE_DELEGATION_MDT_DOULEUR_PHYSIQUE_KEY",
  NombreVisiteDelegationMdtDouleurPsychiqueKey = "NOMBRE_VISITE_DELEGATION_MDT_DOULEUR_PSYCHIQUE_KEY",
  RepartitionVisiteDelegationMdtDouleurPsychiqueKey = "REPARTITION_VISITE_DELEGATION_MDT_DOULEUR_PSYCHIQUE_KEY",
  NombrePersonnesDistinctesConsultationsIST = "NB_PERSONNES_DISTINCTES_CONSULTATIONS_IST",
  NombreTpsTotalActionADMGestionVisiteMdt = "TPSTOTALACTIONADMGESTIONVISITEMDT"
}