























import { Component, Prop, Vue } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton } from 'bootstrap-vue'
import RessifApexDonutChart from "../templates/donut-charts/RessifApexDonutChart.vue"
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";

@Component({
  components: {
    SearchableVueSelect,
    BButton,
    RessifApexDonutChart,
  },
  name: "donut-aptitude-avis-chart"
})
export default class DonutAptitudeAvisChart extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique;
  @Prop({ required: true }) model!: any;
  @Prop({ required: true }) reference!: any;

  title = "Accompagnement individuel"
  description = "Repartition Aptitude/Avis par Dossiers distinct avec au moins un passage infirmerie"

  tableFields = [
    { key: "categorie", label: "Catégorie", type: "text", editable: true },
    { key: "nombre", label: "Nombre", type: "number", editable: true },
    { key: "actions", label: "Actions", editable: false },
  ]
  sampleDatas = [
    {
      id: 1,
      categorie: "Apte",
      nombre: 25,
    },    
    {
      id: 2,
      categorie: "Inapte",
      nombre: 2,
    },      
    {
      id: 3,
      categorie: "Sans avis",
      nombre: 90,
    }
  ]

  addLinePayload = {
    categorie: "Libelle",
    nombre: 0,
  }
  seriesArr : any[] | null = null;
  categoriesArr : any[] | null = null;
  
  typeGraph = TypeGraphEnum.Individuel;
  modelCopy: any | null = null;
  
  created() {
    this.modelCopy = JSON.parse(JSON.stringify(this.model));
  }

  onRowDeleted(payload: any){
    this.sampleDatas = this.sampleDatas.filter(x => x.id !== payload.id)
    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      let dataArray = this.sampleDatas.map((x: any) => parseInt(x.nombre))
      let seriesArray = this.sampleDatas.map((x: any) => x.categorie)

      this.seriesArr = dataArray
      this.categoriesArr = seriesArray as any[]
      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef].updateOptions(
        {
          labels: this.categoriesArr
        }, false, true, true
      )
    }, 200);
  }
  onRowAdded(payload: any){
    this.sampleDatas = payload.values
    
    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      let dataArray = this.sampleDatas.map((x: any) => parseInt(x.nombre))
      let seriesArray = this.sampleDatas.map((x: any) => x.categorie)

      this.seriesArr = dataArray
      this.categoriesArr = seriesArray as any[]
      (this.$refs[payload.graphRef] as any).$refs[payload.graphRef]?.updateOptions(
        {
          labels: this.categoriesArr
        }, false, true, true
      )
    }, 200);
  }
  async loadCategorieSocioPro(graphRef: any) {
    await this.$http.ressifnet.rapportActivites
      .repartitionAptitudeAvis(this.modelCopy)
      .then((response: any) => {
        let dataArray = response.map((x: any) => parseInt(x.nombre))
        let seriesArray = response.map((x: any) => x.categorie)

        this.seriesArr = dataArray
        this.categoriesArr = seriesArray as any[]
        this.sampleDatas = response as any[];


        (this.$refs[graphRef] as any).$refs[graphRef]?.updateOptions(
          {
            labels: this.categoriesArr
          }, false, true, true
        );
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = false;
      }).catch((e: any) => {
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = true;
        (this.$refs[graphRef] as any).disabledButtonTrueData= false;

        let message = e.response?.data?.errors ? 
          `Un problème est survenu lors du chargement du graphique: ${Object.values(e.response.data.errors)[0]}` :
          "Un problème est survenu lors du chargement du graphique";

        this.$swal({
          title: 'Erreur',
          text: message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      })
  }

  async loadTrueData(graphRef: any) {
    await this.loadCategorieSocioPro(graphRef);
  }

  toggleExportToList(graphRef: any) {
    this.$emit('toggle-export-to-list', graphRef)
  }
  baseConfig = {
      series: [280, 200, 220],
      chartOptions: {
        chart: {
          type: 'donut',
        },
        labels: [
          'Apte',
          'Inapte',
          'Sans avis',
        ],  
        dataLabels: {
          style: {
            fontSize: '17px'
          }
        },
        legend: {
          position: 'bottom',
          fontSize: '17px',
          offsetX: 50,
          width: 550,
          formatter: function (val:any , opts:any) 
          {
            //  let total = 0;            
            //  for (let x of opts.w.config.series) { total += x; } 
            let selected = opts.w.config.series[opts.seriesIndex] 
            return opts.w.config.labels[opts.seriesIndex] + " (" + selected +")";
          }
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val + ""
            }
          }
        },
    }
    
  }
}
