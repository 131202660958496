






























import { Component, Prop, Vue } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton } from "bootstrap-vue";
import RessifApexPieChart from "../templates/pie-charts/RessifApexPieChart.vue";
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";

@Component({
  components: {
    SearchableVueSelect,
    BButton,
    RessifApexPieChart,
  },
  name: "actions-by-type-action-chart",
})
export default class ActionsByTypeActionChart extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique;
  @Prop({ required: true }) model!: any;
  @Prop({ required: true }) reference!: any;

  title = "Entreprise - Administratif";
  description =
    "(401) Nombre d'actions réalisées par type d'action";

  tableFields = [
    { key: "typeAction", label: "Type d'action", type: "text", editable: true },
    { key: "nombre", label: "Nombre", type: "number", editable: true },
    { key: "actions", label: "Actions", editable: false },
  ];

  sampleDatas = [
    {
      id: 1,
      typeAction: "Réunion RH",
      nombre: 35,
    },
    {
      id: 2,
      typeAction: "Réunion HSE",
      nombre: 55,
    },
    {
      id: 3,
      typeAction: "Point informel",
      nombre: 10,
    },
    {
      id: 4,
      typeAction: "Groupe de travail",
      nombre: 15,
    },
  ];

  addLinePayload = {
    domaine: "Autres",
    nombre: 0,
  };

  seriesArr: any[] | null = null;
  categoriesArr: any[] | null = null;

  typeGraph = TypeGraphEnum.Individuel;
  modelCopy: any | null = null;

  created() {
    this.modelCopy = JSON.parse(JSON.stringify(this.model));
  }

  onRowDeleted(payload: any) {
    this.sampleDatas = this.sampleDatas.filter((x) => x.id !== payload.id);

    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      let dataArray = this.sampleDatas.map((x: any) => parseInt(x.nombre));
      let seriesArray = this.sampleDatas.map((x: any) => x.domaine);

      this.seriesArr = dataArray;
      this.categoriesArr = seriesArray as any[];

      (this.$refs[payload.graphRef] as any).$refs[
        payload.graphRef
      ].updateOptions(
        {
          labels: this.categoriesArr,
        },
        false,
        true,
        true,
      );
    }, 200);
  }

  onRowAdded(payload: any) {
    this.sampleDatas = payload.values;
    setTimeout(() => {
      let dataArray = this.sampleDatas.map((x: any) => parseInt(x.nombre));
      let seriesArray = this.sampleDatas.map((x: any) => x.domaine);

      this.seriesArr = dataArray;
      this.categoriesArr = seriesArray as any[];

      (this.$refs[payload.graphRef] as any).$refs[
        payload.graphRef
      ]?.updateOptions(
        {
          labels: this.categoriesArr,
        },
        false,
        true,
        true,
      );
    }, 200);
  }

  async loadActionsByTypeAction(
    graphRef: any
  ) {
    await this.$http.ressifnet.rapportActivites
      .repartitionActionsByTypeAction(this.modelCopy)
      .then((response: any) => {
          console.log(response)
        let dataArray = response.map((x: any) => parseInt(x.nombre));
        let seriesArray = response.map((x: any) => x.typeAction);

        this.seriesArr = dataArray;
        this.categoriesArr = seriesArray as any[];
        this.sampleDatas = response as any []

        (this.$refs[graphRef] as any).$refs[graphRef]?.updateOptions(
          {
            labels: this.categoriesArr,
          },
          false,
          true,
          true,
        );

        (this.$refs[graphRef] as any).$refs[graphRef].chart
          .render()
          .then(() => {
            (this.$refs[this.reference] as any).loading = false;
            (this.$refs[this.reference] as any).locked = false;
          });
      })
      .catch((e: any) => {
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = true;
        (this.$refs[graphRef] as any).disabledButtonTrueData = false;

        let message = e.response?.data?.errors
          ? `Un problème est survenu lors du chargement du graphique: ${
              Object.values(e.response.data.errors)[0]
            }`
          : `Un problème est survenu lors du chargement du graphique ${this.constructor.name}`;

        console.error(e);
        this.$swal({
          title: "Erreur",
          text: message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      });
  }

  async loadTrueData(graphRef: any) {
    await this.loadActionsByTypeAction(graphRef);
  }

  toggleExportToList(graphRef: any) {
    this.$emit("toggle-export-to-list", graphRef);
  }
  
  baseConfig = {
    series: [35, 10, 20],
    chartOptions: {
      labels: ["Réunion HSE", "Réunion RH", "Groupe de travail"],
      dataLabels: {
        style: {
          fontSize: "17px",
        },
      },
      legend: {
        position: "bottom",
        fontSize: "17px",
        width: 550,
        offsetX: 50,
        formatter: function (val: any, opts: any) {
          let selected = opts.w.config.series[opts.seriesIndex];
          return opts.w.config.labels[opts.seriesIndex] + " (" + selected + ")";
        },
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val;
          },
        },
      },
    },
  };
}
