






























import { Component, Prop, Vue } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { BButton } from "bootstrap-vue";
import RessifApexPieChart from "../templates/pie-charts/RessifApexPieChart.vue";
import { CharteGraphique } from "@/api/models/options/charteGraphique/charteGraphique";
import { TypeGraphEnum } from "@/api/models/enums/typeGraph";
import { RapportActiviteConfig } from "@/api/models/rapportActivites/rapportActiviteTemplate";
@Component({
  components: {
    SearchableVueSelect,
    BButton,
    RessifApexPieChart,
  },
  name: "pie-amt-edp-type-poste-by-familles-risques-chart",
})
export default class PieAmtEdpTypePosteByFamillesRisquesChart extends Vue {
  @Prop({ required: true }) charteGraphique!: CharteGraphique;
  @Prop({ required: true }) model!: any;
  @Prop({ required: true }) reference!: any;
  @Prop({ required: true }) familleRisque!: string
  
  title = "Entreprise - AMT - Etudes de poste";
  description = "(209) Répartition des types de poste pour la famille de risque '" + this.familleRisque + "' sur les EDP";
  tableFields = [
    { key: "typePoste", label: "Type de Poste", type: "text", editable: true },
    { key: "nombre", label: "Nombre de type de poste", type: "number", editable: true },
    { key: "actions", label: "Actions", editable: false },
  ];
  sampleDatas = [
    {
      id: 1,
      typePoste: "Type de poste n°1",
      nombre: 35,
    },
    {
      id: 2,
      typePoste: "Type de poste n°2",
      nombre: 55,
    },
  ];
  addLinePayload = {
    typePoste: "Autre",
    nombre: 0,
  };
  seriesArr: any[] | null = null;
  categoriesArr: any[] | null = null;
  typeGraph = TypeGraphEnum.Individuel;
  modelCopy: any | null = null;
  created() {
    this.modelCopy = JSON.parse(JSON.stringify(this.model));
  }
  onRowDeleted(payload: any) {
    this.sampleDatas = this.sampleDatas.filter((x) => x.id !== payload.id);
    // Nécessaire car editable-table demande un délai pour l'update
    setTimeout(() => {
      let dataArray = this.sampleDatas.map((x: any) => parseInt(x.nombre));
      let seriesArray = this.sampleDatas.map((x: any) => x.typePoste ?? "");
      this.seriesArr = dataArray;
      this.categoriesArr = seriesArray as any[];
      (this.$refs[payload.graphRef] as any).$refs[
        payload.graphRef
      ].updateOptions(
        {
          labels: this.categoriesArr,
        },
        false,
        true,
        true,
      );
    }, 200);
  }
  onRowAdded(payload: any) {
    this.sampleDatas = payload.values;
    setTimeout(() => {
      let dataArray = this.sampleDatas.map((x: any) => parseInt(x.nombre));
      let seriesArray = this.sampleDatas.map((x: any) => x.typePoste ?? "");
      this.seriesArr = dataArray;
      this.categoriesArr = seriesArray as any[];
      (this.$refs[payload.graphRef] as any).$refs[
        payload.graphRef
      ]?.updateOptions(
        {
          labels: this.categoriesArr,
        },
        false,
        true,
        true,
      );
    }, 200);
  }
  async loadData(
    graphRef: any,
    config?: RapportActiviteConfig,
  ) {
    this.modelCopy.maxCategoryNumber = config?.maxCategoryNumber;
    this.modelCopy.otherLibelle = config?.otherLibelle;
    this.modelCopy.familleRisqueName = this.familleRisque;
    await this.$http.ressifnet.rapportActivites
      .repartitionAmtEdpFamilleRisquesByTypePosteCommand(this.modelCopy)
      .then((response: any) => {
        let dataArray = response.map((x: any) => parseInt(x.nombre));
        let seriesArray = response.map((x: any) => x.typePoste ?? "");
        this.seriesArr = dataArray;
        this.categoriesArr = seriesArray as any[];
        this.sampleDatas = response.map((x: any) => {
          return {
            id: x.id,
            typePoste: x.typePoste,
            nombre: x.nombre,
          };
        });
        (this.$refs[graphRef] as any).$refs[graphRef]?.updateOptions(
          {
            labels: this.categoriesArr,
          },
          false,
          true,
          true,
        );
        (this.$refs[graphRef] as any).$refs[graphRef].chart
          .render()
          .then(() => {
            (this.$refs[this.reference] as any).loading = false;
            (this.$refs[this.reference] as any).locked = false;
          });
      })
      .catch((e: any) => {
        (this.$refs[graphRef] as any).loading = false;
        (this.$refs[graphRef] as any).locked = true;
        (this.$refs[graphRef] as any).disabledButtonTrueData = false;
        let message = e.response?.data?.errors
          ? `Un problème est survenu lors du chargement du graphique: ${
              Object.values(e.response.data.errors)[0]
            }`
          : `Un problème est survenu lors du chargement du graphique ${this.constructor.name}`;
        console.error(e);
        this.$swal({
          title: "Erreur",
          text: message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      });
  }
  async loadTrueData(graphRef: any, config?: RapportActiviteConfig) {
    await this.loadData(graphRef, config);
  }
  toggleExportToList(graphRef: any) {
    this.$emit("toggle-export-to-list", graphRef);
  }
  baseConfig = {
    series: [35, 55],
    chartOptions: {
      labels: ["Type de poste n°1", "Type de poste n°2"],
      dataLabels: {
        style: {
          fontSize: "17px",
        },
      },
      legend: {
        position: "bottom",
        fontSize: "17px",
        width: 550,
        offsetX: 50,
        formatter: function (val: any, opts: any) {
          let selected = opts.w.config.series[opts.seriesIndex];
          return opts.w.config.labels[opts.seriesIndex] + " (" + selected + ")";
        },
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val;
          },
        },
      },
    },
  };
}
