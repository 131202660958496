import { render, staticRenderFns } from "./DouleurPsychiqueConsultationsMDTChart.vue?vue&type=template&id=18133fa4&"
import script from "./DouleurPsychiqueConsultationsMDTChart.vue?vue&type=script&lang=ts&"
export * from "./DouleurPsychiqueConsultationsMDTChart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports